<template>
    <main class="main-content">
        <h1>Let's build software together!</h1>
        <p>Our ambition is to propel our clients to new heights. Fueled by a team of passionate developers who deeply comprehend the customer's needs and expectations, we embrace projects of all scales. From nascent startups to well-established businesses, we're here to serve and surpass your technology goals!</p>
    <h2>Our Clover Products</h2>
        <div class="product-grid">
        
          <div class="product-card">
            <!-- <img src="../assets/logo_plain.png" alt="LayAway!" class="product-image"> -->
            <a href="https://www.clover.com/appmarket/apps/2W947CS3BSKEE"><h2>LayAway!</h2></a>
            <p>Layaway Solution</p>
          </div>
          <div class="product-card">
            <!-- <img src="../assets/logo_plain.png" alt="Integracion Movil" class="product-image"> -->
            <a href="https://www.clover.com/appmarket/apps/ANT2JVJBGTDYJ"><h2>Integracion Movil</h2></a>
            <p>Integration with Evertec's Mobile Payment Solution</p>
          </div>
          <div class="product-card">
            <!-- <img src="../assets/logo_plain.png" alt="Wine Dispenser" class="product-image"> -->
            <a href="https://www.clover.com/appmarket/apps/FP3N2474GT04R"><h2>Wine Dispenser</h2></a> 
            <p>WineEmotion Integration System (Clover)</p>
          </div>
           <div class="product-card">
              <!-- <img src="../assets/logo_plain.png" alt="Wine Dispenser" class="product-image"> -->
              <a href="https://www.clover.com/appmarket/apps/MZM800SRBYRVA"><h2>Business Calling</h2></a> 
              <p>VoIP Services</p>
            </div>
        </div>
         <h2>Additional Services</h2>
       <div class="product-grid">
              <div class="product-card">
                <!-- <img src="../assets/logo_plain.png" alt="LayAway!" class="product-image"> -->
                <h2>Business Calling & IT Support</h2>
                <p>Managed services including Cloud, VoiP & Business IT.</p>
                <router-link to="/services/business-product">Business Calling See Our Services</router-link>
                <p><router-link to="/contact">Contact us</router-link></p>
              </div>
            </div>      
    </main>
    
</template>

<style scoped>
.main-content {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.2em;
    color: #333;
}

.product-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.product-card {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.product-image {
  max-width: 100%;
  height: auto;
}
</style>
