import { createRouter, createWebHistory } from 'vue-router'
import MainComponent from './components/MainComponent.vue'
import TermsComponent from './components/TermsComponent.vue'
import PrivacyComponent from './components/PrivacyComponent.vue'
import ContactComponent from './components/ContactComponent.vue'
import AboutComponent from './components/AboutComponent.vue'
import TermsLayaway from './components/terms/TermsLayaway.vue'
import TermsBfi from './components/terms/TermsBfi.vue'
import TermsIntegracion from './components/terms/TermsIntegracion.vue'
import TermsWine from './components/terms/TermsWine.vue'
import BusinessProductVue from './components/services/BusinessProduct.vue'
import TermsVoip from './components/terms/TermsVoip.vue'

const routes = [
    { path: '/', component: MainComponent },
    { path: '/terms', component: TermsComponent },
    { path: '/privacy', component: PrivacyComponent },
    { path: '/contact', component: ContactComponent },
    { path: '/about', component: AboutComponent },
    { path: '/terms/layaway', component: TermsLayaway },
    { path: '/terms/bfi', component: TermsBfi },
    { path: '/terms/integracion', component: TermsIntegracion },
    { path: '/terms/wine', component: TermsWine },
    { path: '/services/business-product', component: BusinessProductVue },
    { path: '/terms/voip', component: TermsVoip }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
