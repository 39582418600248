<template>
  <div id="app">
    <HeaderComponent />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
body {
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1 0 auto;
  padding-bottom: 100px;
  /* Adjust this value to match the height of your footer */
  box-sizing: border-box;
  /* Include padding in element's total height and width */
}

.FooterComponent {
  flex-shrink: 0;
}
</style>

