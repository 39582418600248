<template>
    <div class="contact">
        <h2>Contact Us</h2>
        <p>Feel free to reach out to us via email, phone, or our social media channels:</p>
        <p><a href="mailto:bryanne@atomicalsoftwares.com">bryanne@atomicalsoftwares.com</a></p>
        <p><a href="tel:13866261150">1(386) 626-1150</a></p>
        <p>
            <a href="https://www.atomicalsoftwares.com">Website</a> |
            <a href="https://www.facebook.com/atomicalsoftwares">Facebook</a> |
            <a href="https://www.twitter.com/atomsoft">Twitter</a>
        </p>
        <h3>Or chat with us using the button below!</h3>
    </div>
</template>

<script>
export default {
    name: 'ContactComponent'
}
</script>

<style scoped>
.contact {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.2em;
    color: #333;
}

.contact h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.contact p {
    line-height: 1.6;
}

.contact a {
    color: #1a0dab;
}
</style>
