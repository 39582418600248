<template>
        <div class="container">
                <div class="row">
                        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                <p class="c5"><span class="c2">Atomical VoIP Service Terms & Conditions</span></p>
                                <p class="c5"><span class="c2">End User License Agreement</span></p>
                                <p class="c5"><span class="c2">ATOMICAL SOFTWARE’S, LLC</span></p>
                                <p class="c5"><span class="c2">Effective date 2023-10-12</span></p>
                                <p class="c5"><span class="c2">VoIP Service Agreement</span></p>

                                <p class="c8">
                                        <span class="c2">End-user = Business or individual subscribing to Atomical VoIP
                                                Service.</span>
                                </p>

                                <ol class="c7 lst-kix_list_1-0 start" start="1">
                                        <!-- ... existing list items ... -->

                                        <li class="c0"><span>Service Termination: Atomical may terminate the VoIP service for
                                                        end-users for any
                                                        breach of these terms or misuse of the service. End-users may terminate
                                                        their subscription at
                                                        any time.</span></li>

                                        <!-- Added Terms -->

                                        <li class="c0"><span>User Obligations: Users are responsible for securing their internet
                                                        connection and account credentials. Users are liable for all calls made
                                                        through their account, including unauthorized access.</span></li>

                                        <li class="c0"><span>Emergency Services: The VoIP service may not support emergency
                                                        calls. Users should maintain an alternative method of contacting
                                                        emergency services.</span></li>

                                        <li class="c0"><span>Payment and Charges: Users agree to timely payment of all
                                                        applicable service fees. Service may be suspended or terminated for
                                                        non-payment.</span></li>

                                        <li class="c0"><span>Data Privacy: Call data is used to improve service quality. Calls
                                                        may be recorded in compliance with applicable laws. Users consent to the
                                                        recording and monitoring of calls for quality assurance.</span></li>

                                        <li class="c0"><span>Indemnification: Users will indemnify Atomical from claims arising
                                                        from their use of the service, including legal fees.</span></li>

                                        <li class="c0"><span>Restrictions: Users may not use the service for unlawful, harmful,
                                                        or abusive purposes, including auto-dialing, excessive calls,
                                                        harassment, or fraud.</span></li>

                                        <li class="c0"><span>Technical Requirements: Users must ensure their equipment and
                                                        software are compatible with the VoIP service. Service quality may vary
                                                        depending on internet speed and equipment.</span></li>

                                        <li class="c0"><span>Service Modifications: Atomical reserves the right to modify or
                                                        discontinue the service with notice. Users will be informed of
                                                        significant changes to the service.</span></li>

                                <li class="c0"><span>Limitation of Liability: Atomical’s liability is limited to direct
                                                damages, not to exceed the amount paid for the service during the
                                                relevant period, excluding indirect, incidental, or consequential
                                                damages.</span></li>

                                <li class="c0"><span>Service Availability: Service availability may be affected by
                                                maintenance, network or power outages, or internet service disruptions.
                                                Atomical is not liable for service interruptions.</span></li>

                                <li class="c0"><span>Dispute Resolution: Disputes arising under these terms will be
                                                resolved by arbitration in Atomical's jurisdiction, unless otherwise
                                                required by law.</span></li>

                                <li class="c0"><span>International Use: Users are responsible for compliance with laws
                                                governing international use and exportation of the service and related
                                                technology.</span></li>

                                <li class="c0"><span>Entire Agreement: These terms constitute the entire agreement
                                                between Atomical and the end-user, superseding previous agreements. If
                                                any part of these terms is held invalid, the rest of the terms will
                                                remain in effect.</span></li>
                        </ol>

                        <p class="c6"><span class="c2"></span></p>
                </div>
        </div>
</div></template>
