<template>
    <header>
        <router-link to="/"><img src="../assets/logo.png" alt="Company Logo" class="logo"></router-link>
        
        <nav>
            <ul class="nav-links">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About</router-link></li>
                <li>
                    <div class="dropdown">
                        <button class="dropbtn">Services</button>
                        <div class="dropdown-content">
                            <li><router-link to="/services/business-product">Business Calling</router-link></li>
                        </div>
                    </div>
                </li>
                <li><router-link to="/contact">Contact us</router-link></li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'HeaderComponent'
}
</script>

<style scoped>
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.logo {
    height: 60px;
    width: auto;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.nav-links a {
    color: #333;
    text-decoration: none;
    font-weight: 600;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
}

.dropdown-content a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn {
    background-color: #ffffff;
    color: #333;
    padding: 0; /* Adjusted padding */
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #f1f1f1;
}

.nav-links li {
    align-items: center;
    display: flex;
}

.nav-links a:hover, .dropbtn:hover {
    background-color: #f1f1f1;
    border-radius: 4px;
}
</style>
