<template>
    <div class="about">
        <h2>About Us</h2>
        <p>Atomical Softwares LLC is a software development company that specializes in creating innovative solutions for
            businesses. We have a team of dedicated professionals who are passionate about technology and committed to
            delivering high-quality software.</p>
        <p>We'd love to keep things simple, from our products to our website. While our pages are simple, our knowledge is
            vast and infinite as the net.</p>
    </div>
</template>

<script>
export default {
    name: 'AboutComponent'
}
</script>

<style scoped>
.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.2em;
    color: #333;
}

.about h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.about p {
    line-height: 1.6;
}</style>
