<template>
    <div class="business-product">
        <h2>Hosted PBX/Phone System</h2>
        <p>Moving to our hosted PBX can instantly save you money, increase employee productivity, and improve customer
            service. With our hosted PBX, you get the most advanced features available and you are guaranteed that you never
            have to worry about technology obsolescence or paying for upgrades in the future.</p>

    <p><router-link to="/contact">Contact us</router-link> or chat with us now to learn more.</p>

        <h3>All The Basic Features You Expect</h3>
        <ul>
            <li>Local, Long Distance, And International Calling</li>
            <li>Keep Your Existing Telephone Numbers</li>
            <li>Automated Attendant</li>
            <li>Customized Music-on-hold</li>
            <li>Extensions Or DIDs On A Per-user Basis</li>
            <li>Employee Directory</li>
            <li>Voicemail</li>
            <li>Message Waiting Lights</li>
            <li>Do Not Disturb</li>
            <li>Ring Groups</li>
            <li>Conferencing</li>
        </ul>

        <h3>Advanced and Powerful Features To Drive Your Business</h3>
        <ul>
            <li>Call Routing To Mobile Phones</li>
            <li>Voicemail-To-Email</li>
            <li>Voicemail Transcription</li>
            <li>Call Recording</li>
            <li>Call Center Functionality</li>
            <li>User Dashboard And Softphone Support</li>
            <li>Operator Console</li>
            <li>Mobile Application That Makes Any Mobile Device A True PBX Extension</li>
            <li>Fax Support From Traditional Fax Machines, Desktops, Or Mobile Phones</li>
            <li>Business SMS</li>
        </ul>

        <h3>Flexible and Scalable</h3>
        <p>You pay only for the capacity that you need. As your business grows, you can quickly and easily add additional
            trunks, users, and extensions. If your business is seasonal, you can reduce capacity just as easily during the
            off-season.</p>

        <h3>Save Money</h3>
        <p>Save 50% or more on your monthly phone bill and up to 90% in deployment costs compared to traditional phone
            systems.</p>

        <h3>Reliable</h3>
        <p>Our Hosted PBX/Phone Service is delivered using a geographically redundant infrastructure. We currently have
            nodes in industry-leading colocation facilities in New York, Las Vegas, and Dallas. Our seamless failover using
            active-active application servers, combined with our network architecture, guarantees the highest uptime levels
            possible.</p>

        <h3>Personalized Customer Service and No Headaches</h3>
        <p>Your phone system is the life-blood of your business. We understand that outsourcing this important function can
            be scary. But we are local business people, just like you. We know that our best asset is the relationship that
            our people will have with you. No runaround, no giant call center to call when you need help.</p>

        <h3>We Set It Up For You</h3>
        <p>No Do-It-Yourself Setup. We work with you, step-by-step, to make sure that your new phone system is properly
            set-up on the first try. If needed, we can send someone to do the complete installation and setup for you.</p>

        <h3>Easy To Manage</h3>
        <p>You can make many changes to the phone system through our online portal. Don't want to make them yourself, no
            problem. Call us, tell us what you want, and we will do it for you.</p>

        <h3>Straight Forward Billing</h3>
        <p>No surprises and an easy to understand monthly invoice. Have questions, call us. It's that simple.</p>
    </div>
</template>

<script>
export default {
    name: 'BusinessProduct'
}
</script>

<style scoped>
.business-product {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.2em;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow-wrap: break-word;
}

.business-product h2,
.business-product h3 {
    margin-bottom: 20px;
}

.business-product p,
.business-product ul {
    margin-bottom: 20px;
}

.business-product ul {
    list-style-type: disc;
    padding-left: 40px;
}
</style>
