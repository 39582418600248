<template>
    <div class="container"><table width="100%">
    <tbody>
    <tr>
    <td width="79%">
    <p><strong>BFi </strong><em>by: Atomical Software’s</em></p>
    </td>
    </tr>
    </tbody>
    </table>
    <h1>Terms</h1>
    <table width="93%">
    <tbody>
    <tr>
    <td colspan="2" width="100%">
    <h2>Application INFORMATION</h2>
    </td>
    </tr>
    <tr>
    <td width="23%">
    <p>App name:</p>
    </td>
    <td width="77%">
    <p>BFi</p>
    </td>
    </tr>
    <tr>
    <td width="23%">
    <p>App ID:</p>
    </td>
    <td width="77%">
    <p>6H3F8KMQCV4MA</p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Atomical Softwares LLC.</p>
    <p><strong>Services Uptime<br></strong></p>
    <p>Atomical Software’s may conduct service updates &amp;/or maintanence to it's app or services. While we thrieve for cutomer's satisfaction, 100% uptime is not guranteed. We reserve the right to suspend services in an orderly fasion to improve our services towards you.</p>
    <p><strong>Sales/Demo Accounts<br></strong></p>
    <p>Due to the nature of this application, Sales/Demo Accounts are not authorized to utilize this application. You must contact <a href="mailto:bryanne@atomicalsoftwares.com">bryanne@atomicalsoftwares.com</a> for further support regarding your account. (This policy is due to hardware requirements).</p>
    <p><strong>Support</strong></p>
    <p>We provide email &amp; phone (only for installation/setup) support for basic plans.<br>If you require more assistance, please consider our support plan which includes SLA &amp; Phone Support During Business Hours.</p>
    <table width="93%">
    <tbody>
    <tr>
    <td width="59%">
    <p>Developer Notes:</p>
    </td>
    <td width="41%">
    <p>This application is a tool to provide effective use of technology. Atomical Software’s is dedicated to service and privacy, if you have a concern of would like to know more about our services, apps and policies, please contact us! <a href="mailto:support@atomicalsoftwares.com">support@atomicalsoftwares.com</a></p>
    <p>We’re more than happy to assist!</p>
    </td>
    </tr>
    </tbody>
    </table></div>
</template>