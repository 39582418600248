<template>
     <div class="privacy-policy">
        <h1>Privacy Policy</h1>


    
    <div class=article-list>
    <div class=container><p id=h.gurx4udd0grw class="c43 title wysiwyg-text-align-center"><span class=c40>Atomical Softwares </span><span class=c36>LLC </span></p>
    <p class="c43 title wysiwyg-text-align-center"><span class=wysiwyg-underline><strong><span class=c36>Privacy Policy</span></strong></span></p>
    <p class="c20 wysiwyg-text-align-center"><span class=wysiwyg-underline><span class=c4>As of (Version Date): October 4th, 2018</span></span></p>
    <p class="c20 c26"> </p>
    <h3 id=h.ihe715q1ayna class="c1 wysiwyg-text-align-center"><strong><span class=c27>Disclaimer</span></strong></h3>
    <p class="c39 wysiwyg-text-align-center"><span class=c4>By accessing/utilizing Atomical Software’s LLC websites, tools &amp; services, </span></p>
    <p class="c39 wysiwyg-text-align-center"><span class=c4>you are bound to this privacy policy.</span></p>
    <p class="c20 c26"> </p>
   <p class=c0> </p>
    <p class=c0> </p>
    <h2 id=h.u46ykbxdxr52 class=c30><span class="c7 c38">Collection of PII</span></h2>
    <h4 id=h.xq9662ikraah class="c22 c32"><span class=c34>Personally Identifiable Information</span></h4>
    <p class=c39><span class=c4>Atomical Software's LLC may collect, store &amp; use the following kind of PII from:</span></p>
    <ul class="c10 lst-kix_vs179j7vhd-0 start">
    <li class=c9>
    <h3 id=h.nepvs7n61l7y style=display:inline><span class=c27> Merchants</span></h3>
    </li>
    </ul>
    <p class=c17><span class=c4> (Business Owners)</span></p>
    <ul class="c10 lst-kix_vs179j7vhd-1 start">
    <li class=c11><span class=c4>Business Name</span></li>
    <li class=c11><span class=c4>Address</span></li>
    <li class=c11><span class=c4>Email Address</span></li>
    <li class=c11><span class=c4>Owner Name</span></li>
    </ul>
    <h4 id=h.15jjghb2zi1o class=c8><span class=c34> How do we use this information?</span></h4>
    <ul class="c10 lst-kix_c56bti76ric5-0 start">
    <li class=c3><span class=c4>Your portfolio as a business are the tools we utilize to archive, order &amp; sort, customize &amp; improve your overall experience throughout our services.</span></li>
    </ul>
    <p class=c17><span class=c4> When do we collect this information?</span></p>
    <ul class="c10 lst-kix_3yejzdbvb4uv-0 start">
    <li class=c3><span class=c4>When you register/install our services on any given platform we provide.</span></li>
    </ul>
    <ul class="c10 lst-kix_34j819mt9val-0 start">
    <li class=c9>
    <h3 id=h.l2g8bdz9gfrq style=display:inline><span class=c27>Customers</span></h3>
    </li>
    </ul>
    <p class=c17><span class=c4> (Regular users)</span></p>
    <ul class="c10 lst-kix_34j819mt9val-1 start">
    <li class=c11><span class=c4>First &amp; Last Names</span></li>
    <li class=c11><span class=c4>Phone Number</span></li>
    <li class=c11><span class=c4>How (-the customer-) interacts with the business.</span></li>
    <li class=c11><span class=c4>Credit/Debit Card Numbers</span></li>
    <li class=c11><span class=c4>Customer Sign up's or 3rd party social logins (Facebook/Google/Twitter/Amazon sign in)</span></li>
    </ul>
    <h3 id=h.opqx566s953g class="c8 c23"><span class=c34>How do we use this information?</span></h3>
    <ul class="c10 lst-kix_c18fgpeg1sqh-0 start">
    <li class=c3>Technical Support Channel ( <span class=c15><a class=c6 href="https://www.google.com/url?q=https://atomicalsoftwares.zendesk.com&amp;sa=D&amp;ust=1526065211427000">here </a></span><span class=c4>)</span></li>
    <li class=c3><span class=c4>Apps &amp; Services</span></li>
    </ul>
    <p class=c17><span class=c4> When do we collect this information?</span></p>
    <ul class="c10 lst-kix_tc76lehvggdt-0 start">
    <li class=c3><span class=c4>When you’re utilizing our services to keep a record of your transaction activities in some of our SaaS (Software as a Service) platforms.</span></li>
    <li class=c3><span class=c4>To provide business owners with feedback about how often their customers visit their business, how much time they spend &amp; observe customer traffic on our analytics platform.</span></li>
    <li class=c3><span class=c4>We collect but do not store or process Credit/Debit cards numbers but we do <strong>not </strong>store or process such information. They are immediately encrypted &amp; exchanged for a tokenism service to support more secure &amp; improve the overall time a transaction consumes.</span><span class=c4><br></span></li>
    <li class=c3><span class=c4>Sign in services provided by external social media platforms are bound to share your account identification number or profile/account email. This information is only used to identify you (the customer) in our platform &amp; services &amp; we do not provide access to third-parties/merchants unless otherwise stated &amp; you (the customer) grant permission.</span></li>
    </ul>
    <p><span class=c4>When do we (Atomical Software's LLC)/merchants use this "token" card data or customer's information:</span></p>
    <ul class="c10 lst-kix_tc76lehvggdt-0 start">
    <li class=c3><span class=c4>Customers visiting participating businesses that are part of the "Customer Sense"/"Sense" network have implemented the <a href=https://google.github.io/physical-web/ target=_blank rel=noopener>Physical Web</a> technologies to enable any device supporting Bluetooth Beacons to interact with a nearby business, such as:</span>
    <ul>
    <li class=c3><span class=c4>Receiving a more personalized welcome</span></li>
    <li class=c3><span class=c4>Getting the menu instantly (electronically)</span></li>
    <li class=c3><span class=c4>Checkout sooner &amp; faster, keep a record of your purchases &amp; never loose them!</span></li>
    </ul>
    </li>
    <li><span class=c4>We or the merchant may enable welcome messages/promotions which are not necesarily targeted to customers, for example:</span>
    <ul>
    <li><span class=c4>(Fictitious Merchant) Vega's Burgers might have enabled the Physical Web technology on their business. When you walk in/our of the business, you might receive a general -non targeted specifically to any customer- offers to invite you to re-visit their business. These welcome/farewell offers/messages may be turned off at any given time from our app's settings on your mobile phone.</span></li>
    </ul>
    </li>
    </ul>
    <p class=c17><span class=c4>We also collect information when you Open a Support Ticket or enter information on our site. We also collect information when you provide us with feedback on our products or services.</span></p>
    <p class=c17><span class=c4>In order to personalize your experience we also use your information to:</span></p>
    <ul class="c10 lst-kix_b1f7t5dwa06d-0 start">
    <li class=c11><span class=c4>Improve our services/websites.</span></li>
    <li class=c11><span class=c4>Improve our support responses &amp; requests.</span></li>
    <li class=c11><span class=c4>Follow up after you’ve contacted us (live chat, email or phone inquiries) to acknowledge we’ve satisfied your requests needs.</span></li>
    </ul>
    <h2 id=h.h1te7usysjqn class=c19><span class=c5>How do we protect your information?</span></h2>
    <p class=c2><span class=c4> All information you supply is encrypted via Secure Socket Layer (SSL) technology. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information. Merchants can use our Sense Platform for analytics of their customer's traffic but cannot identify/target a specific customer under any circumstance. Our physical web platform does not share or provide access to third-parties &amp; information is only provided on a need to know basis.<br></span></p>
    <p class=c2><span class=c4>A good example of need-to-know basis is a transaction activity. Merchants can create an order and you (the customer) is about to pay, by utilizing your Physical Web enabled content/app/services, you can make a payment, obtain a receipt &amp; terminate a transaction without providing card data details or other time &amp; security taking tasks.</span></p>
    <h2 class=c2><span class=c4>Transfer of your Personal Data</span></h2>
    <p>We may transfer your Personal Data outside of the European Economic Area ("<strong>EEA</strong>") to other members of the Atomical Software's LLC and other recipients. Certain recipients who process your Personal Data on our behalf may transfer your Personal Data outside the EEA to a country that does not provide an adequate level of protection to your Personal Data. Where such transfers of your Personal Data are made they will be made in accordance with applicable law. Where your Personal Data is transferred outside the EEA, this may include transfers on the basis of Standard Contractual Clauses, or as is otherwise permitted by applicable law. Standard Contractual Clauses are a form of data processing contract approved by the European Commission. You can find a copy of these clauses <a href=https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en>here</a></p>
    <p>If you would like to find out more about any such transfers, please contact our Data Protection Representative who can be contacted on the following details - <a href=mailto:info@atomicalsoftwares.com>info@atomicalsoftwares.com</a> </p>
    <h2>Retention of your Personal Data</h2>
    <p> </p>
    <p>In general, we expect to keep your Personal Data for as long as is necessary for the purposes for which it was obtained from the date of collection or, where you enter into a contractual arrangement with us, following the end of the performance of our contract or when you engagement with our Atomical Software's LLC ends.</p>
    <p>For example, we may hold your Personal Data if we are processing an ongoing claim or believe in good faith that the law or a relevant regulator may reasonably in our view expect or require us to preserve your Personal Data.</p>
    <p>We do not sell or rent your personal information to any third parties for marketing purposes.</p>
    <p>If you would like to know more about how long we will retain your Personal Data, please contact our Data Protection Representative at info@atomicalsoftwares.com</p>
    <p>In general, we expect to keep your Personal Data for as long as is necessary for the purposes for which it was obtained from the date of collection or, where you enter into a contractual arrangement with us, following the end of the performance of our contract or when you engagement with our Atomical Software's LLC ends.</p>
    <p>For example, we may hold your Personal Data if we are processing an ongoing claim or believe in good faith that the law or a relevant regulator may reasonably in our view expect or require us to preserve your Personal Data.</p>
    <p>We do not sell or rent your personal information to any third parties for marketing purposes.</p>
    <p>If you would like to know more about how long we will retain your Personal Data, please contact our Data Protection Representative at info@atomicalsoftwares.com</p>
    <h2 class=c2><span class=c4>Disclosure of your Personal Data</span></h2>
    <p><span class=c4>We disclose your personal data to:</span></p>
    <ul>
    <li><span class=c4>Regulatory authorities (Law enforcement etc)</span></li>
    <li><span class=c4>IT Services/Providers whom provide maintenance or support on our infrastructure (only when it's imminent that external support is required &amp; it directly affects you (the merchant) or the customer.</span></li>
    </ul>
    <h2 id=h.kwy6gzkskalf class=c19><span class=c5>Children's Privacy</span></h2>
    <p class="c2 c32"><span class=c4>Our Service is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13 without obtaining parental consent. If we learn that personally identifiable information has been collected on the Service from persons under 13 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under 13 years of age has obtained an account on the Service, then you may alert us at info@atomicalsoftwares.com and request that we delete that child's personally identifiable information from our systems.</span></p>
    <hr style=page-break-before:always;display:none>
    <p class=c0> </p>
    <h2 id=h.rwkgx2p08o3o class=c19><span class=c42>We do not</span><span class=c5>:</span></h2>
    <ul class="c10 lst-kix_n1opdhk0j56-0 start">
    <li class="c28 c46">
    <h3 id=h.o3u21q1fq60g style=display:inline><span class=c27>Collect/Store/Process data about:</span></h3>
    </li>
    </ul>
    <ul class="c10 lst-kix_n1opdhk0j56-1 start">
    <li class="c2 c16"><span class=c4>Registrations</span></li>
    <li class="c2 c16"><span class=c4>Newsletters</span></li>
    <li class="c2 c16"><span class=c4>Your devices, computers or services accessing our services/websites</span></li>
    <li class="c2 c16"><span class=c4>Visits &amp; usage of our services/websites</span></li>
    </ul>
    <ul class="c10 lst-kix_n1opdhk0j56-0">
    <li class="c46 c28">
    <h3 id=h.20ohf0z5o09l style=display:inline><span class=c27>Perform Information Sharing:</span></h3>
    </li>
    </ul>
    <h4 id=h.rj413z5zwy3h class="c22 wysiwyg-indent4"><span class=c34>(Third Party Disclosure) </span><span class=c4>With any third-party/affiliates/partners/service providers</span></h4>
    <h2 id=h.x0zvdwodbntj class=c19><span class=c5>Making changes to your information</span></h2>
    <ul class="c10 lst-kix_ac3og8wu7hj0-0 start">
    <li class="c2 c16"><span class=c4>Collected information can be updated through the Setup app (Merchants) or Customers app (for the Merchant’s customers), data will be synchronized with our services although transaction data/history of previous Layaway's will remain intact.</span></li>
    </ul>
    <ul class="c10 lst-kix_ac3og8wu7hj0-1 start">
    <li class="c2 c37">Information collected through other channels, regardless of the service’s origin (website, apps, services) can be requested for deletion or updating by contacting us at info@atomicalsoftwares.com with a request that we delete/update your data from our database. We will use commercially reasonable efforts to honor your request. We may retain an archived copy of your data for a variety of reasons, such as to comply with our legal requirements, resolve disputes, enforce our agreements, or any other lawful business purpose.<hr style=page-break-before:always;display:none></li>
    </ul>
    <h2 class=c19>Your Rights &amp; How To Exercise Them</h2>
    <p>You have a number of rights in relation to your Personal Data, which are set out in this Section 9. <strong>In particular these rights include the right to object to processing of your Personal Data where that processing is carried out for our legitimate interests</strong>. Note that in certain circumstances these rights might not be absolute.</p>
    <ul>
    <li>Right of Access
    <ul>
    <li>You have the right to request a copy of the Personal Data held by us about you and to access the information which we hold about you. We will only charge you for making such an access request where we feel your request is unjustified or excessive.</li>
    </ul>
    </li>
    <li>Right to Rectification
    <ul>
    <li>You have the right to have any inaccurate Personal Data which we hold about you updated or corrected.</li>
    </ul>
    </li>
    <li>Right to Erasure
    <ul>
    <li>In certain circumstances, you may also have your personal information deleted, for example if you exercise your right to object (see below) and we do not have an overriding reason to process your Personal Data or if we no longer require your Personal Data for the purposes as set out in this notice.</li>
    </ul>
    </li>
    <li>Right to Restriction of Processing
    <ul>
    <li>You have the right to ask us to restrict processing your Personal Data in certain cases, including if you believe that the Personal Data we hold about you is inaccurate or our use of your information is unlawful. If you validly exercise this right, we will store your Personal Data and will not carry out any other processing until the issue is resolved.</li>
    </ul>
    </li>
    <li>Right of Data Portability
    <ul>
    <li>You may request us to provide you with your Personal Data which you have given us in a structured, commonly used and machine-readable format and you may request us to transmit your Personal Data directly to another data controller where this is technically feasible. This right only arises where: (1) we process your Personal Data with your consent or where it is necessary to perform our contract with you; <strong><u>and</u></strong> (2) the processing is carried out by automated means.</li>
    </ul>
    </li>
    <li>Right to Object
    <ul>
    <li>
    <p>You have a right to object at any time to the processing of your Personal Data where we process your Personal Data on the legal basis of pursuing our legitimate interests.</p>
    <p><strong>Please note you have right to object to the processing of your personal data for direct marketing. </strong></p>
    <p><strong>You may unsubscribe from Atomical Software's LLC communications (including but not limited to information regarding our recurring reminders &amp;/or services which maintains a recurring or "interval-led" communication with you (the merchant) or your customers) by contacting info@atomicalsoftwares.com</strong></p>
    </li>
    </ul>
    </li>
    </ul>
    <p>You can exercise any of these rights by submitting a request to our Data Protection Representative at <a href=mailto:info@atomicalsoftwares.com>info@atomicalsoftwares.com</a> .</p>
    <p>We will provide you with information on any action taken upon your request in relation to any of these rights without undue delay and at the latest within 1 month of receiving your request. We may extend this up to 2 months if necessary however we will inform you if this arises. Please note that we may ask you to verify your identity when you seek to exercise any of your data protection rights.</p>
    <p>You also have the right to lodge a complaint with the Data Protection Commission. For further information see <a href=http://www.dataprotection.ie>www.dataprotection.ie</a>.</p>
    <p> </p>
    <h2 id=h.xpdaldguvyqy class=c19>Changes to this <span class=c15><a class=c6 href="https://www.google.com/url?q=http://www.atomicalsoftwares.com/privacy/&amp;sa=D&amp;ust=1526065211430000">Privacy Policy</a></span></h2>
    <p class=c2>We may modify this privacy policy at any time. If we do, we will post the revised version of the terms <span class=c15><a class=c6 href="https://www.google.com/url?q=http://www.atomicalsoftwares.com/privacy/&amp;sa=D&amp;ust=1526065211430000">here</a></span><span class=c4> &amp; they will take effect from the time at which it is first published. You are advised to check the Terms from time to time for any updates or changes that may affect you the user or customers of your business utilizing our services/websites, Atomical Software's LLC strives to satisfaction, we’ll push email updates but cannot guarantee delivery based on outside factors (email provider availability/up-time, invalid user email address, etc).</span></p>
    <p class=c0> </p>
    <p class=c2>You should periodically check <span class=c15><a class=c6 href="https://www.google.com/url?q=http://www.atomicalsoftwares.com/privacy/&amp;sa=D&amp;ust=1526065211431000">here</a></span><span class=c4> for the most up-to-date version of this privacy policy. Any changes to the privacy policy will not be retroactively applied and will not alter how we handle personally identifiable information we previously collected from you.</span></p>
    <p class=c0> </p>
    <h2 id=h.pg9z5kjl44cf class=c18><span class=c5>California Online Privacy Protection Act</span></h2>
    <p class=c2><span class=c41> </span></p>
    <p class=c2>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. - See more at: <span class=c15><a class=c6 href="https://www.google.com/url?q=http://consumercal.org/california-online-privacy-protection-act-caloppa/%23sthash.0FdRbT51.dpuf&amp;sa=D&amp;ust=1526065211431000">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a></span></p>
    <h3 id=h.gb7txaz9w0wt class=c29> </h3>
    <h2 id=h.gb7txaz9w0wt-1 class=c18><span class=c5>According to CalOPPA, we agree to the following:</span></h2>
    <p class=c2><span class=c4>Users can visit our site anonymously.</span></p>
    <p class=c2><span class=c4>Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.</span></p>
    <p class=c2><span class=c4>Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.</span></p>
    <p class=c2><span class="c24 c7">Response to Do Not Track Signals</span></p>
    <p class=c0> </p>
    <ul class="c10 lst-kix_rnzaqejtem6f-0 start">
    <li class="c2 c28">Certain web browsers may provide an option by which you may have the browser inform websites or internet services you visit that you do not wish to have personally identifiable information about your activities tracked by cookies or other persistent identifiers across time and across third-party Internet websites, online or cloud computing services, online applications, or mobile applications. These are commonly called “do not track” signals. All of our services/apps/websites, including our <span class="c15 c7"><a class=c6 href="https://www.google.com/url?q=https://atomicalsoftwares.zendesk.com&amp;sa=D&amp;ust=1526065211433000">Support Website</a></span>,<span class=c4> do not respond to such signals because we do not collect “cookies” or other persistent identifiers to monitor/review/create a history of your activities.</span></li>
    </ul>
    <p class="c0 c32"> </p>
    <ul class="c10 lst-kix_fz0pq8c8dngz-0 start">
    <li class="c2 c28">We <span class="c7 c42">do not</span><span class=c4> allow third parties to collect personally identifiable information about a user’s online activities, over time and across different sites, services, and applications, when that user uses our site, service, or application. </span></li>
    </ul>
    <p class=c0> </p>
    <h2 id=h.q3psptidcc6w class=c18><span class=c5>Fair Information Practices</span></h2>
    <p class=c33><span class=c31> </span></p>
    <p class=c47><span class=c4>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</span></p>
    <p class=c33><span class="c24 c7">In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</span></p>
    <p class=c33><span class=c4>We will notify you via email</span></p>
    <p class=c33> <span class=c7>•</span><span class=c4> Within 7 business days upon learning that such event has taken place<br></span></p>
    <p class=c0> </p>
    <p class=c2>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>
    <h2 id=h.n6fz7p3nufg7 class=c19><span class=c5>Acknowledgement</span></h2>
    <p class=c2><span class=c4>This EULA is concluded between Atomical Software’s, LLC and our users (including our websites, services &amp; support channel). Atomical Software’s, LLC is solely responsible for the content thereof.</span></p>
    <p class=c0> </p>
    <h2 id=h.a24yapa9dmmf class=c19><span class=c5>Scope of License</span></h2>
    <p class=c2><span class=c4>The license granted to the users of our websites, tools &amp; services, limited to a non-transferable license in compliance with DNTs &amp; PII.</span></p>
    <p class=c0> </p>
    <h2 id=h.sem1bswnnam7 class=c19><span class=c5>Product Claims</span></h2>
    <p class=c2><span class=c4>Atomical Software's LLC and you, the user, acknowledge that Atomical Software’s, LLC, is responsible for addressing any claims of you, the user, or any third party relating to the Licensed services/websites or theusers’s possession and/or use of that Licensed Services/Websites, including, but not limited to: (i) product liability claims; (ii) any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. This EULA does not limit Atomical Software's LLC’s liability to the user beyond what is permitted by applicable law. Atomical Software’s LLC will not be responsible for misuse of our services/websites which could incur in the invalid data of their records.</span></p>
    <p class=c0> </p>
    <h2 id=h.i8ya1z0swk0 class=c19><span class=c5>Legal Compliance</span></h2>
    <p class=c2><span class=c4>Our license represents and warrant that (i) our users are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist supporting country; and (ii) he/she is not listed on any U.S. Government list of prohibited or restricted parties.</span></p>
    <h2 class=c0>Legal Compliance Obligation</h2>
    <p class="c0 wysiwyg-indent2">Your personal information will be processed in order to comply with legal obligations which our company is subject to comply with. Your personal information that we collect will be processed in order to comply with the following legal obligations:</p>
    <ul>
    <li class="c0 wysiwyg-indent2">To comply with the Irish &amp; European Union company &amp; law;</li>
    <li class="c0 wysiwyg-indent2">Comply with applicable European &amp; Irish Laws;</li>
    <li class="c0 wysiwyg-indent2">Comply with the European Union Central Bank PISP regulations.</li>
    </ul>
    <p class=c0> </p>
    <h2 id=h.h7oh0tp8uys2 class=c19><span class=c5>Developer Name and Address</span></h2>
    <p class=c2><span class=c4>The developer may be reached at Atomical Software’s, LLC, info@atomicalsoftwares.com to which any merchant questions, complaints or claims with respect to our services or websites should be directed. Our mailing address: PO Box 4071, Bayamon, PR, 00958.</span></p>
    <h2 id=h.jj7ofcdd891n class=c19><span class=c5>Reasonable Advance Notice</span></h2>
    <p class=c2><span class=c4>(30 days) will be provided to current subscribers of our services/websites before ceasing making services/websites available through a service/app developed by Atomical Software’s, LLC, before it’s taken down or remove it from current distribution due to: (a) an allegation or actual infringement of any intellectual property right or right of publicity or privacy of any third party (b) an allegation or actual defamation, (c) an allegation or determination that an app does not comply with applicable law, (d) Atomical Software’s, LLC ceasing to do business, or (e) Atomical Software’s, LLC filing a petition in bankruptcy, dissolving or otherwise failing or unable to pay your debts as they become due. In the case Atomical Software’s, LLC removes a service/website, users will be given an opportunity to copy/export their data before the final ceasing of service/website. A service/website may be removed at any time from our available catalog of services/websites, if it is determined that the service/website: (i) may infringe or otherwise violate the intellectual property rights or any other rights of any third party; (ii) violates any applicable.</span></p>
    <p class=c0> </p>
    <h2 id=h.9qffb36630oi class=c19><span class=c5>Services Security</span></h2>
    <p class=c2>Atomical Software’s, LLC does not represent or guarantee that any service/website developed by Atomical Software’s, LLC will be free from loss, corruption, attack, viruses, interference, hacking, or other security intrusions and Atomical Software’s, LLC disclaims any liability relating thereto.<span class=c7> </span><span class=c4>We cannot, however, ensure or warrant the security of any information you transmit to us or store on the Service, and you do so at your own risk. We also cannot guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. If we learn of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. We may post a notice through the Service if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a free-of-cost written notice of a security breach you should notify contact us at info@atomicalsoftwares.com.</span></p>
    <p class=c0> </p>
    <p class=c0> </p>
    <hr style=page-break-before:always;display:none>
    <p class=c0> </p>
    <p class=c20><span class=c4>Product specific disclaimers:</span></p>
    <h2 id=h.2ovuuynryinj class=c19><span class=c5>LayAway! App</span></h2>
    <ul class="c10 lst-kix_wiv49dxuogs-0 start">
    <li class="c2 c28"><span class=c4>This SaaS will always send a confirmation of the Layaway record generation to the customer &amp; a final SMS when the layaway is paid in full. Merchant may have the ability to switch off/on in-between reminders to their customers in the settings section of the SaaS application.</span></li>
    <li class="c2 c28"><span class=c4>Merchants are required to have their own layaway policies &amp; notify users of the usage of this app including transactional SMS reminders/notifications.</span></li>
    </ul>
    <ul class="c10 lst-kix_wiv49dxuogs-1 start">
    <li class="c2 c16"><span class=c4>We cannot &amp; will not enforce a standard policy as businesses have their own unique requirement &amp; state applicable laws.</span></li>
    </ul>
    <ul class="c10 lst-kix_wiv49dxuogs-0">
    <li class="c2 c28">Atomical Software’s LLC will provide training &amp; support upon request without additional charges. Subject to Atomical Software’s LLC capacity to handle multiple requests at once. We suggest always generating a ticket at our <span class=c15><a class=c6 href="https://www.google.com/url?q=https://atomicalsoftwares.zendesk.com/hc/en-us/requests/new&amp;sa=D&amp;ust=1526065211438000">support website</a></span><a class=c6 href="https://www.google.com/url?q=http://www.atomicalsoftwares.com/support&amp;sa=D&amp;ust=1526065211438000"> </a><span class=c4>Merchants will be responsible to track &amp; maintain their customer data accordingly, Atomical Software’s LLC will not take responsibility for lost records (happened due to Merchant’s error) although we will assist in any manner possible to satisfy the needs or guidance to our Merchants .</span></li>
    <li class="c2 c28"><span class=c4>Merchant will maintain liability in proper utilization of SMS Service provided as a service through Atomical Softwares LLC. Although customers may opt-out of SMS service, it’s still the merchant’s responsibility to have their customers acknowledge they shall be receiving SMS notifications &amp; the expected quantity &amp; frequency (based on merchant’s adjustments on the app’s settings).</span></li>
    <li class="c2 c28"><span class=c4>Legal claims of the SMS service regarding misuse or excessive use is under the liability of the merchant. Atomical Softwares LLC provides reports for merchant about the usages of transactional SMS deliveries.</span></li>
    </ul>
            <!-- Add the HTML content of your privacy policy here -->
            <section>
              <h2>Collection of PII</h2>
              <!-- ... Rest of your existing HTML content ... -->

              <h2>Additional Information for Specific Applications</h2>
              <h3>Layaway</h3>
              <p>We collect information on the items users are putting on layaway, the duration of the layaway period, and payment information to facilitate the layaway process. We do not share this information with third parties except for processing payments.</p>

              <h3>Integracion Movil</h3>
              <p>We collect device information, usage data, and location data to provide and improve the mobile integration services. Users can opt-out of location tracking via the app settings.</p>

              <h3>Wine Dispenser</h3>
              <p>We collect data on the types and quantities of wine dispensed to improve our services and offer personalized recommendations. This data is anonymized and aggregated before analysis.</p>

              <h3>Business Calling</h3>
              <p>We collect call data, including call duration, time, and anonymized content data to improve call quality and services. We do not share this data with third parties without explicit user consent.</p>
            </section>
          </div>
    <p class=c0> </p>
    <p class="c20 wysiwyg-text-align-center"><span class=c4>Atomical Softwares LLC</span></p>
    <p class="c20 wysiwyg-text-align-center"><span class=c4>PO Box 4071</span></p>
    <p class="c20 wysiwyg-text-align-center"><span class=c4>Bayamon PR, 00958</span></p>
    <p class="c20 wysiwyg-text-align-center"><span class=c4>+1(386) 626-1150</span></p></div>
    </div>
</template>

<style scoped>
.privacy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.2em;
    color: #333;
}
</style>
