<template>
    <div class="terms">
        <div class="intro">
            <h2 class="text-center">Terms of Use</h2>
            <p class="text-center">Please select a term of use from our products.</p>
        </div>
        <div class="row articles">
            <router-link to="/terms/layaway">LayAway!</router-link>
            <router-link to="/terms/bfi">BFi App</router-link>
            <router-link to="/terms/wine">Wine Dispenser Application</router-link>
            <router-link to="/terms/integracion">Integration Movil</router-link>
            <router-link to="/terms/voip">Business Calling</router-link>
        </div>
    </div>
</template>

<style scoped>
.terms {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.intro {
    text-align: center;
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.item {
    flex: 1 0 200px;
    margin-bottom: 20px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}</style>
