<template>
    <footer>
        <p>Atomical Softwares LLC © {{ currentYear }}</p> <a href="tel:+13866261150">+1(386) 626-1150</a>
        <p>Atomical Softwares LLC is not affiliated nor endorsed by the respective brands mentioned in this page</p>
        <router-link to="/terms" class="footer-link">Terms of Use</router-link>
        <router-link to="/privacy" class="footer-link">Privacy Policy</router-link>
        <a href="https://twitter.com/atomsoftwares">Twitter  | </a>
        <a href="https://www.facebook.com/atomicalsoftwares">   Facebook</a>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    }
}
</script>

<style scoped>
footer {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    color: #6c757d;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

.terms-link {
  color: #333;
  text-decoration: none;
}

.footer-link {
  color: #333;
  text-decoration: none;
  margin-right: 10px;
}
</style>
